// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "z_t6 d_gS d_cw d_dv";
export var quoteParagraphCenter = "z_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "z_gV d_gV d_cw d_dx";
export var quoteRowLeft = "z_t7 d_bG";
export var quoteRowCenter = "z_t8 d_bD";
export var quoteRowRight = "z_t9 d_bH";
export var quoteWrapper = "z_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "z_gR d_gR";
export var quoteExceptionSmall = "z_rr q_rr";
export var quoteExceptionNormal = "z_rs q_rs";
export var quoteExceptionLarge = "z_rt q_rt";
export var quoteAuthorExceptionSmall = "z_rv q_rv";
export var quoteAuthorExceptionNormal = "z_rw q_rw";
export var quoteAuthorExceptionLarge = "z_rx q_rx";