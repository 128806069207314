// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tr d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_ts d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_tt d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_tv d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_tw d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_tx d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_ty d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rk q_rk";
export var heroExceptionNormal = "v_rl q_rl";
export var heroExceptionLarge = "v_rm q_rm";
export var Title1Small = "v_q3 q_q3 q_qy q_qz";
export var Title1Normal = "v_q4 q_q4 q_qy q_qB";
export var Title1Large = "v_q5 q_q5 q_qy q_qC";
export var BodySmall = "v_q9 q_q9 q_qy q_qS";
export var BodyNormal = "v_rb q_rb q_qy q_qT";
export var BodyLarge = "v_rc q_rc q_qy q_qV";