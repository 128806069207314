// extracted by mini-css-extract-plugin
export var tileContent = "x_tH d_w d_H d_Z";
export var teamTextLeft = "x_tJ d_dv";
export var teamTextCenter = "x_tK d_dw";
export var teamTextRight = "x_tL d_dx";
export var alignLeft = "x_qp d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "x_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "x_qq d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "x_tM d_dW";
export var teamContainerFull = "x_tN d_dT";
export var teamRowWrapper = "x_tP d_cc";
export var teamTileWrapper = "x_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "x_tQ d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "x_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "x_j5 d_j5 d_Z";
export var teamHoverNoGutters = "x_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "x_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "x_tR d_j7";
export var teamInfoWrapperRound = "x_j7 d_j7";
export var teamInfoWrapper = "x_tS d_j8 d_0";
export var teamInfoWrapperNoGutters = "x_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "x_jZ d_jZ";
export var teamImgWrapperAlt = "x_j0 d_j0";
export var teamImgWrapperNoGutters = "x_kb d_kb";
export var teamHeader = "x_tT d_cw";
export var teamHeaderAlt = "x_tV d_cw";
export var teamHeaderNoGutters = "x_tW d_cw d_cD";